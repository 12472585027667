@import url('https://fonts.googleapis.com/css?family=BenchNine:400|Libre+Baskerville:400,400i&display=swap');
@import "~@renaissancerentals/renaissance-component/lib/assets/App";

.hamburger {
  padding: 1rem;
}

.muncher-button {
  letter-spacing: .1em;
  font-size: 1rem;
  @media(min-width: $medium-size) {
    font-size: 1.3rem;
  }
  padding: .6rem 1.8rem;

  a {
    color: white;
  }
}

.heading {
  .emphasized {
    font-family: $main-font;
    font-style: italic;
    text-transform: none;
  }
}

.sort-filters {
  .muncher-button {
    color: $primary-color;
  }

  .muncher-button--active {
    color: $primary-background-color;
  }
}

.section-application {
  .application-heading {
    .muncher-button {
      color: $primary-color;
      font-family: $main-font;
      letter-spacing: normal;
    }

    .muncher-button--transparent {
      color: $primary-color;
      text-decoration: underline;
    }
  }

}

.section-property-location {
  h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: .8rem;

    i {
      font-family: $main-font;
      font-size: .8rem;
    }
  }

  a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: underline;
  }

}

.floorplan-card, .muncher-item-slider {
  .muncher-button {
    padding: inherit;

  }
}

.muncher-badge {
  background-color: $secondary-background-color;

  p {
    color: $primary-color;
  }

  .muncher-button {
    background-color: $primary-color;
    padding: inherit;

    p {
      color: $primary-background-color;
    }
  }

  .selected {
    color: $primary-color;
  }
}

.floorplan--featured {
  .muncher-button {
    padding: inherit;
  }
}

.section-card {
  color: $primary-color;

  i {
    font-family: $serif-font;
  }

  .card-body--featured {
    background: $tertiary-background-color;

    .muncher-button {
      background-color: $primary-color;
    }

    .muncher-button--navigate {
      .muncher-button {
        background-color: inherit;
      }

    }

  }
}

.floorplan--featured {
  .muncher-button {
    color: $primary-color;
  }
}

.floorplan-column-right {
  .muncher-button--tertiary {
    background-color: $tertiary-background-color;
  }

  .muncher-button--primary {
    background-color: $primary-color;
  }

  .muncher-button--secondary {
    color: $primary-color;
    background-color: $secondary-background-color;
  }
}

.muncher-dropdown--content {
  .muncher-button--secondary {
    background-color: $secondary-background-color;
    color: $primary-color;

    a {
      color: $primary-color;
    }
  }
}


.hamburger {
  cursor: pointer;
  padding: .5rem;
  color: $secondary-color;
}


.section-pricing-card .tall {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;

  .asterix {
    position: relative;
    top: -0.5em;
    font-size: 1.6rem;
  }
}

@import "src/assets/variables";

.section-hero {
  padding: 0;
  background: $secondary-background-color;
  position: relative;
  video {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
  }
}


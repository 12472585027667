@import "src/assets/variables";

.home-section-dense-feature {

  .dense-feature--left {
    h1 {
      font-family: $main-font;
      font-size: 2.2rem;
      color: $secondary-color;
    }

    .dense-feature-heading {
      font-family: $heading-font;
      text-transform: uppercase;
      letter-spacing: .05rem;
      font-size: 1.6rem;
    }

    li {
      padding-bottom: 1rem;
      margin-left: 1rem;
    }
  }

  .dense-feature--right {
    background: $secondary-color;
    padding: 1rem;

    hr {
      border-top: 1px solid white;
    }

    ul {
      column-count: 2;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        white-space: nowrap;
        padding-left: 1rem;
        font-family: $heading-font;
        text-transform: uppercase;
        letter-spacing: .05rem;
        font-size: 1.6rem;
        color: white;
      }

      .plus {
        padding-left: 0;
      }

      .total {
        color: $primary-color;
      }
    }
  }

  @media (min-width: $medium-size) {
    display: flex;

  }

}
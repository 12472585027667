@import "src/assets/variables";

.section-amenities {
  ul {
    padding-left: 4rem;
    padding-right: 2rem;
  }


  @media (min-width: $medium-size) {
    ul {
      padding-left: 6rem;
      padding-right: 6rem;

      li {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

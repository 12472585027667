@import "src/assets/variables";

.header-main {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 50;
  text-align: left;
  background: url(Header-background.png);
  box-shadow: 0px 4px 4px rgba(128, 128, 128, 0.50);

  h3 {
    font-family: $nav-font;
  }

  .div-mobile {
    display: block;

    .right {
      .muncher-icon {
        color: $primary-color;
      }

    }
  }

  .div-main {
    display: none;
  }

  .header {
    padding: .05rem;
    display: flex;
    align-items: center;

    nav {
      color: $primary-color;
    }

    .left {
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      text-align: center;
    }

    .right {
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      text-align: center;
    }

    .muncher-modal {
      background-color: $primary-background-color;
      padding: 1rem;
      left: 0;
      top: 0;
      width: 80%;
      overflow: scroll;
      max-height: 85vh;

    }
  }

  @media (min-width: $large-size) {
    .div-mobile {
      display: none;
    }

    .div-main {
      display: block;

      nav {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }

      .left {
        display: flex;
        margin-left: 1rem;

      }

      .right {
        display: flex;
        margin-right: 1rem;
      }

      p {
        font-size: .7rem;
      }

      h3 {
        display: inline-block;
        padding: 0 1rem;
        margin: 0;
      }

      .logo {
        display: inline-block;
      }
    }
  }
}

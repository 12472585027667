@import "src/assets/variables";

.home-section-feature {
  h2 {
    font-size: 2rem;
  }

  ul {
    padding-left: 4rem;

    li {
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: 1.5rem;
    }
  }
}

@media (min-width: $medium-size) {
  .home-section-feature {
    .container {
      max-width: 55rem;
      display: flex;
      align-content: center;
      justify-content: center;

      .feature-btn {
        text-align: left;
        margin: 0;

      }

      .feature-left, .feature-right {
        width: 25rem;

      }


      .feature-right {
        + h2 {
          text-align: left;
          padding-left: 6rem;
        }
      }

      ul {
        column-count: 1;
        padding-left: 1rem;
      }
    }
  }
}

@media (min-width: $large-size) {
  .home-section-feature {
    .container {
      width: 65rem;

      .feature-left, .feature-right {
        width: 25rem;
      }


      .feature-right {
        + h2 {
          padding-left: 6rem;
        }
      }

      ul {
        padding-left: 1rem;
      }
    }
  }
}
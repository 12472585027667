$primary-color: #13294b !default;
$secondary-color: #a7bdb1 !default;
$primary-background-color: white !default;
$secondary-background-color: #e9eeeb !default;
$tertiary-background-color: $secondary-color !default;
$main-font: 'Libre Baskerville', serif !default;
$heading-font: 'BenchNine', sans-serif !default;
$italic-font: $main-font !default;
$button-font: $heading-font !default;
$button-color: $primary-background-color !default;
$button-background-color: $primary-background-color !default;
$button-primary-background-color: $secondary-color !default;
$button-secondary-background-color: $primary-color !default;
$button-secondary-color: $primary-background-color !default;
$info-header-color: $primary-color !default;
$search-unit-header-background-color: $secondary-background-color !default;
$floorplan-featured-color: $primary-color !default;
$floorplan-hover-color: $primary-background-color !default;
$floorplan-hero-featured-color: $primary-color !default;
@import "~@renaissancerentals/renaissance-component/lib/assets/variables";

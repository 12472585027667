@import "src/assets/variables";

.home__section--amenities {
  color: white;
  background: url(./bike-background.png) center/cover;

  h2 {
    font-size: 2rem;
  }

  ul {
    padding: 0 4rem;
    margin: 0;

    li {
      text-transform: uppercase;
      font-family: $heading-font;
      font-size: 1.5rem;
    }
  }

  .dense-amenities-left {
    h2 {
      text-align: left;
      padding-left: 3rem;
    }
  }
}

@media (min-width: $medium-size) {
  .home__section--amenities {
    .container {
      display: flex;
      align-content: center;
      justify-content: center;

      ul {
        column-count: 1;
      }
    }
  }
}

@import "src/assets/variables";

.home__section--hero {
  background-image: url(./Hero-background-mobile.png);
  color: white;
  padding: 1rem;

  h1 {
    //font-size: 2.5rem;
    letter-spacing: .09rem;
  }


  h3 {
    text-transform: none;
    font-family: $main-font;
    padding-left: 0;
    padding-right: 0;

    i {
      color: $secondary-color;
    }

    a {
      text-decoration: underline;
      text-underline-offset: .2rem;
    }

  }

  h4 {
    //text-transform: none;
    //font-family: $main-font;
    font-size: 1.4rem;
  }

  img {
    margin: 2rem;
    width: 8rem;
  }

  text-align: center;

  a {
    color: $primary-background-color;

  }

  a:hover {
    color: $secondary-background-color;
  }

  .btn {
    font-size: 1.5rem;
  }

  p {
    font-size: .8rem;
  }

  .sub-section--image {
    display: flex;
    align-items: center;
  }
}

@media (min-width: $medium-size) {
  .home__section--hero {
    background-image: url(./Hero-background-mobile.png);

    h1 {
      font-size: 2.5rem;
      letter-spacing: .25rem;

    }


    h3 {
      text-transform: none;
      font-family: $main-font;
      font-size: 1.3rem;
      letter-spacing: .1rem;

      + p {
        padding-left: .5rem;
        padding-right: .5rem;
      }

    }

    .sub-section {
      column-count: 2;

      img {
        margin: 1rem;
        width: 10rem;
      }
    }
    .sub-section--image {
      display: block;
    }
  }
}

@media (min-width: $large-size) {
  .home__section--hero {

    h1 {
      + h3 {
        padding-left: 10vw;
        padding-right: 10vw;
      }
    }

    h3 {
      padding-left: 1rem;
      padding-right: 1rem;

      + p {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }

    h4 {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .container {
      padding-left: 12vw;
      padding-right: 12vw;

    }
  }
}
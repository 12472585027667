@import "src/assets/variables";

.home-section-footer {
  footer {
    background-image: url(./Footer-background-mobile.png);
    padding: 1em;
    color: white;
    background-position: center;
    background-repeat: no-repeat;

    p {
      font-size: .9rem;
      font-family: $main-font;
      a {
        color: white;
      }

      a:hover {
        color: $secondary-color;
      }
    }

    @media (min-width: $medium-size) {
      background: $primary-color;

      p {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        flex-wrap: wrap;
      }

      img {
        padding-right: 1em;
      }
    }
  }
}


@import "src/assets/variables";

.section__hero--dense {
  padding: 0;
  color: white;
  background: $primary-color;

  video {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
  }

  .hero--left {
    position: relative;

    .hero--banner {
      padding: 0;
      background: $secondary-color;

      p {
        text-align: center;
        font-size: 1.2rem;
        margin: 0;
        padding-bottom: 1rem;
      }

      .banner--action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          text-transform: none;
          color: $primary-color;
        }

        h1, h2 {
          margin: 0 .5rem;
        }
      }

    }
  }

  .hero--right {
    background: $primary-color;
    margin: 1rem;
    padding-bottom: 1rem;
    text-align: center;

    a {
      color: white;
    }

    h1 {
      font-size: 2.8rem;
      margin-bottom: 0;
    }

    h3 {
      font-size: 1.7rem;
      margin-top: 0;
    }

    p {
      margin: 1rem;
    }

  }

  @media (min-width: $medium-size) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    video {
      width: 50vw;
    }
    .hero--left {
      .hero--banner {
        p {
          font-size: 1.4rem;
        }

        .banner--action {
          h1 {
            font-size: 2.6rem;
          }
        }

      }
    }
    .hero--right {
      h1 {
        font-size: 3.5rem;
      }

      h3 {
        font-size: 2rem;

      }
      p {
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }
  }
  @media (min-width: $large-size) {
    .hero--right {

      p {
        margin-left: 5rem;
        margin-right: 5rem;
      }
    }
  }
  @media (min-width: $extra-large-size) {
    .hero--right {

      p {
        margin-left: 6rem;
        margin-right: 6rem;
      }
    }
  }
}


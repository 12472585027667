@import "src/assets/variables";

.logo {
  img {
    height: 3.5rem;
    padding: .5rem;
    @media(min-width: $medium-size) {
      height: 3.8rem;
      padding: 1rem;
    }
  }
}